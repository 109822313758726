<template>
	<div id="projectsdetailpage" class="pageContainer container projectsdetailpage">
		<!-- <h1 class="pageTitle" :aria-label="$t('home-title-page')">
			{{ $t("home-title-page-split1")}}
			<span>{{ $t("home-title-page-split2") }}</span>
		</h1> -->

		<div class="parallaxContainerProjects row">
			<div class="col-12 col-lg-8 parallaxGroup1 order-1 order-lg-2">
				<div v-for="(image, index) in imageGroup1.slice(0, 9)" :class="['rellax', image.addclass, 'imageParallax' + (index + 1)]" :data-rellax-speed="image.speed">
					<img
					 
					:key="image.id"
					:src="image.src"
					:alt="image.alt"
					
					
					importance="high"
					/>
				</div>
				
			</div>

			<div class="pageContentText col-12 col-lg-4 order-lg-2">
				<div class="mini-title">{{ $t('ultimos_projetos') }}</div>
				<h1 v-html="projects.title"></h1>
				<p v-html="projects.content"></p>
				<!-- <p class="newsDate">{{ projects.pubdata }}</p> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "projectsdetailpage",
		data() {
			return {
				projects: "",
				imageGroup1: [],
				hasImage: false,
				rellax: null
			};
		},
		created() {
			let selectedProjects = this.$route.params.id;

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" +
						this.$i18n.locale +
						"/projects-detail?id=" +
						selectedProjects
				)
				.then(response => {
					this.projects = response.data;
					this.imageGroup1 = response.data.gallery1;
					this.hasImage = true
					
					this.$nextTick(() => {
                		this.rellax = new Rellax('.rellax', { speed: 1 })
						this.$eventBus.$emit("pageFinishLoad", true);
            		})
				});
		},
		watch: {
			$route(to, from) {
				if (this.hasImage) this.rellax.refresh()
			}
		}
	};
</script>

<style lang="scss">

@media (min-width: 1200px){
		.container-xl, .container-lg, .container-md, .container-sm, .projectsdetailpage.container {
			max-width: 1140px;
		}
	}
	#projectsdetailpage .parallaxGroup1 img{
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative!important;
    
    /* &.imageParallax1{
        z-index: 1;
        right: 10%;
        top: 40%;
    }

    &.imageParallax2{
        z-index: 3;
        top: 80%; 
        left: 10%;
    }

    &.imageParallax6{
        z-index: 2;
        top: 0%;
        left: 25%;
    }   */
}
.mini-title{
	text-transform: uppercase;
	font-size: 15px;
	color: #c47c5a;
	font-family: 'Oswald';
	letter-spacing: 1.2px;
}
#projectsdetailpage  .imageParallax1{
	float: left !important;
	/*height: 450px;
	width: 50%;*/
}
#projectsdetailpage  .imageParallax1 img, #projectsdetailpage  .imageParallax5 img, #projectsdetailpage  .imageParallax7 img, #projectsdetailpage  .imageParallax9 img{
	object-fit: cover;
    width: 450px;
    height: 450px;
}

#projectsdetailpage .imageParallax2,#projectsdetailpage .imageParallax6 , #projectsdetailpage  .imageParallax4, #projectsdetailpage  .imageParallax8{
	float: right !important;
	right: 70px;
	z-index: 3;
	/*top: -5%;
	height: 450px;
	width: 50%;*/
}
#projectsdetailpage .imageParallax2 img, #projectsdetailpage  .imageParallax4 img, #projectsdetailpage  .imageParallax6 img, #projectsdetailpage  .imageParallax8 img{
	object-fit: cover;
    width: 370px !important;
    height: 600px !important;
}
#projectsdetailpage .imageParallax4{
	/*top: 4%;
	right: 70px;
	z-index: 3;*/
}
#projectsdetailpage .imageParallax5{
	top: 4%;

}
#projectsdetailpage  .imageParallax6{
	top: 4%;
}

#projectsdetailpage  .imageParallax8{
	top: -11%;
}
#projectsdetailpage  .imageParallax9{
	top: -14%;
}

#projectsdetailpage .imageParallax4 img{
	float: right !important;
	right: 70px;
}

#projectsdetailpage .imageParallax3 img, #projectsdetailpage  .imageParallax7 img{
	object-fit: cover;
    width: 450px !important;
    height: 450px !important;
}

	#projectsdetailpage {
		margin-top: 140px;

		h1.pageTitle,
		.h1.pageTitle {
			position: fixed;
		}

		.parallaxContainerProjects {
			padding-top: 150px;
			// padding-bottom: 270px;
			max-height: 3500px;

			.parallaxGroup1 {
				padding-right: 11%;

				img {
					position: relative;
					-webkit-filter: grayscale(0%);
					-moz-filter: grayscale(0%);
					filter: grayscale(0%);
					z-index: 0;
					right: 0;
					left: 0;
					top: 0;
					margin-bottom: 5px;
				}
			}
		}

		.pageContentText {
			height: calc(100vh - 160px);
			position: -webkit-sticky;
			position: sticky;
			/*top: 150px;*/
			right: 5%;
			margin: 0;
			padding: 0;

			h1{
				margin-top:15%;
			}
			.newsDate {
				position: relative;
				bottom: 0;
			}
		}
	}

	@media (max-width: 990px){
		#projectsdetailpage .parallaxContainerProjects {			
			max-height: 100% !important;
		}
		#projectsdetailpage .rellax {
			transform: none !important;
		}
		#projectsdetailpage .col-sm-8{
			max-width: 100%;
		}
		#projectsdetailpage .imageParallax1 img, #projectsdetailpage .imageParallax2 img, #projectsdetailpage .imageParallax3 img, #projectsdetailpage .imageParallax4 img,
		#projectsdetailpage .imageParallax5 img, #projectsdetailpage .imageParallax6 img, #projectsdetailpage .imageParallax7 img, #projectsdetailpage .imageParallax8 img,
		#projectsdetailpage .imageParallax9 img{
			width: 100% !important;
			height: auto !important;
		}
		#projectsdetailpage .imageParallax9, #projectsdetailpage .imageParallax5{
			top: auto !important;
		}
		#projectsdetailpage .imageParallax5{
			top: auto !important;

		}
		#projectsdetailpage  .imageParallax6{
			top: auto !important;
		}

		#projectsdetailpage  .imageParallax8{
			top: auto !important;
		}
		#projectsdetailpage  .imageParallax9{
			top: auto !important;
		}
		#projectsdetailpage .imageParallax2, #projectsdetailpage .imageParallax6, #projectsdetailpage .imageParallax4, #projectsdetailpage .imageParallax8 {
			float: none !important; 
			right: 0px; 
			z-index: 3; 
			top: 0%;
		}
		#projectsdetailpage .parallaxContainerProjects .parallaxGroup1{
			padding-right: 0;
			padding-left: 0;			
		}
		#projectsdetailpage .pageContentText {
			right: 0%; 
			margin: 0; 
			padding: inherit;   
			padding-right: 15px;
    		padding-left: 15px; 
			padding-top: 0;
		}
		#projectsdetailpage .parallaxContainerProjects {
			padding-top: 50px;
		}
		#projectsdetailpage{
			padding-bottom: 150px;
		}
	}
</style>
